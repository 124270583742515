import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';


const BusinessGuard = ({ children }) => {
    const selectedBusinessId = useSelector(state => state.account.selectedBusinessId);
    console.log('business id: ' + selectedBusinessId);

  if (!selectedBusinessId) {
    return <Redirect to="/app/dashboard" />;
  }


  return children;
};

export default BusinessGuard;
