export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';

export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_INITIALIZE = 'ACCOUNT_INITIALIZE';

export const SELECT_BUSINESS = 'SELECT_BUSINESS';
export const SET_SEARCH_STRING = 'SET_SEARCH_STRING';


export const login = (token, user) => {
    return {
      type: LOGIN,
      payload: { token, user },
    };
  };
  
  export const logout = () => {
    return {
      type: LOGOUT,
    };
  };
  
  export const initializeAccount = (isLoggedIn, user, token) => {
    return {
      type: ACCOUNT_INITIALIZE,
      payload: { isLoggedIn, user, token },
    };
  };

export const setSearchString = (searchString) => ({
    type: SET_SEARCH_STRING,
    payload: searchString
});