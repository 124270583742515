import React, { useState, useEffect } from 'react';
import { Link, useLocation  } from 'react-router-dom';
import { useDispatch , useSelector  } from 'react-redux';
import { setSearchString } from '../../../../../store/actions';

const NavSearch = (props) => {
    const { windowWidth } = props;
    const [isOpen, setIsOpen] = useState(windowWidth < 600);
    //const [searchString, setSearchString] = useState(windowWidth < 600 ? '100px' : '');
    const searchString = useSelector(state => state.account.searchString); 
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(setSearchString(''));
        setIsOpen(false);
        // setSearchString(0); // Remove this line
        setTimeout(() => {
            if (windowWidth < 600) {
                document.querySelector('#navbar-right').classList.remove('d-none');
            }
        }, 500);
    }, [dispatch, location]);

    const searchOnHandler = () => {
        if (windowWidth < 600) {
            document.querySelector('#navbar-right').classList.add('d-none');
        }
        setIsOpen(true);
        //setSearchString('200px'); // Remove this line
    };

    const searchOffHandler = () => {
        setIsOpen(false);
        // setSearchString(0); // Remove this line
        setTimeout(() => {
            if (windowWidth < 600) {
                document.querySelector('#navbar-right').classList.remove('d-none');
            }
        }, 500);
    };

    let searchClass = ['main-search'];
    if (isOpen) {
        searchClass = [...searchClass, 'open'];
    }

    const handleSearchChange = (e) => {
        const searchString = e.target.value;
        dispatch(setSearchString(searchString)); // Dispatch action to update search string in Redux store
    };

    /*const searchOnHandler = () => {
        if (windowWidth < 600) {
            document.querySelector('#navbar-right').classList.add('d-none');
        }
        setIsOpen(true);
        setSearchString('200px');
    };

    const searchOffHandler = () => {
        setIsOpen(false);
        setSearchString(0);
        setTimeout(() => {
            if (windowWidth < 600) {
                document.querySelector('#navbar-right').classList.remove('d-none');
            }
        }, 500);
    };

    let searchClass = ['main-search'];
    if (isOpen) {
        searchClass = [...searchClass, 'open'];
    }*/

    return (
        <React.Fragment>
            <div id="main-search" className={searchClass.join(' ')}>
                <div className="input-group">
                    <input type="text" id="m-search" className="form-control" placeholder="Search . . ." style={{ width: searchString }} value={searchString} onChange={handleSearchChange} />
                    <Link to="#" className="input-group-append search-close" onClick={searchOffHandler}>
                        <i className="feather icon-x input-group-text" />
                    </Link>
                    <span className="input-group-append search-btn btn btn-primary" onClick={searchOnHandler}>
                        <i className="feather icon-search input-group-text" />
                    </span>
                </div>
            </div>
        </React.Fragment>
    );
};

export default NavSearch;
