let BACKEND_SERVER = null;
if (process.env.REACT_APP_BACKEND_SERVER) {
  BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER;
} 
/*else {
  BACKEND_SERVER = "http://localhost:5000/api/";
}*/

export const BASENAME = ''; // don't add '/' at end off BASENAME
export const BASE_URL = '/app/dashboard';
export const BASE_TITLE = ' | Tracking App ';
export const API_SERVER = BACKEND_SERVER;

export const FB_APPID = process.env.REACT_APP_FB_APPID;
export const FB_APPSECRET = process.env.REACT_APP_FB_APPSECRET;
export const FB_CONFIG_ID = process.env.REACT_APP_FB_CONFIG_ID;
export const GHL_clientId = process.env.REACT_APP_GHL_clientId;
export const GHL_clientSecret = process.env.REACT_APP_GHL_clientSecret;
export const GHL_redirectUri = process.env.REACT_APP_GHL_redirectUri;

// verified app
/*export const FB_APPID = '440130991039811';
export const FB_APPSECRET = '45ea46631bda3b034c6ac93969526722';
export const FB_CONFIG_ID= '660794762682563';*/

export const CONFIG = {
    layout: 'vertical', // disable on free version
    subLayout: '', // disable on free version
    collapseMenu: false, // mini-menu
    layoutType: 'menu-dark', // disable on free version
    navIconColor: false, // disable on free version
    headerBackColor: 'header-default', // disable on free version
    navBackColor: 'navbar-default', // disable on free version
    navBrandColor: 'brand-default', // disable on free version
    navBackImage: false, // disable on free version
    rtlLayout: false, // disable on free version
    navFixedLayout: true, // disable on free version
    headerFixedLayout: false, // disable on free version
    boxLayout: false, // disable on free version
    navDropdownIcon: 'style1', // disable on free version
    navListIcon: 'style1', // disable on free version
    navActiveListColor: 'active-default', // disable on free version
    navListTitleColor: 'title-default', // disable on free version
    navListTitleHide: false, // disable on free version
    configBlock: true, // disable on free version
    layout6Background: 'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)', // disable on free version
    layout6BackSize: '' // disable on free version
};
