import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT, SELECT_BUSINESS, SET_SEARCH_STRING } from './actions';

export const initialState = {
  token: null,
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  selectedBusinessId: null,
  searchString: ''
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_INITIALIZE: {
      const { isLoggedIn, user, token } = action.payload;
      return {
        ...state,
        isLoggedIn,
        isInitialized: true,
        token,
        user
      };
    }
    case LOGIN: {
      const { token, user } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        token,
        user
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isLoggedIn: false,
        token: null,
        user: null,
        selectedBusinessId:null
      };
    }
    case SELECT_BUSINESS: {
      return {
        ...state,
        selectedBusinessId: action.payload.selectedBusinessId,
      };
    }
    case SET_SEARCH_STRING: // New case to handle setting search string
        return {
          ...state,
          searchString: action.payload
    };
    default: {
      return state;
    }
  }
};

export default accountReducer;