import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { LOGOUT } from './../../store/actions';
import axios from 'axios';
import { API_SERVER } from './../../config/constant';

const AuthGuard = ({ children }) => {
  const account = useSelector((state) => state.account);
  const { isLoggedIn, token } = account;
  const dispatch = useDispatch();

  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await axios.post(API_SERVER + 'users/validateToken', { token });

        if (response.data.valid === false) {
          dispatch({ type: LOGOUT });
        }
      } catch (error) {
        // Handle error
        console.error('Error validating token:', error);
      }
    };

    if (isLoggedIn && token) {
      validateToken();
    }
  }, [isLoggedIn, token, dispatch]);

  if (!isLoggedIn) {
    return <Redirect to="/auth/signin" />;
  }

  // Check if the token has expired
  const decodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  if (decodedToken.exp < currentTime) {
    dispatch({ type: LOGOUT });
    return <Redirect to="/auth/signin" />;
  }

  // Set the Authorization header for all API requests
  axios.defaults.headers.common['Authorization'] = `${token}`;

  return children;
};

export default AuthGuard;